import React from "react";

export default function Header({ title, count }) {
  return (
    <div className="border-b border-b-slate-200 w-full pb-4 px-8 py-[52px] ">
      <div className="flex gap-[10px] items-center">
        <h1 className="text-xl font-medium text-slate-800">
          {title}
          {count ? `(${count})` : ``}
        </h1>
      </div>
    </div>
  );
}
