import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleOrg, updateOrg } from "../services";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Button,
  Select,
} from "@headlessui/react";
import { cn } from "../lib/utils";
import { FaChevronDown } from "react-icons/fa";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { message } from "antd";

const choices = [
  { name: "Тийм", value: true },
  { name: "Үгүй", value: false },
];

export default function EditOrg() {
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [selected, setSelected] = useState();
  const [formValues, setFormValues] = useState({
    // id: id,
    orgName: "",
    register: "",
    phone: "",
    email: "",
    isGiveAward: false,
  });

  useEffect(() => {
    getSingleOrg({ id })
      .then((response) => {
        const orgData = response.data;
        setOrganization(orgData);
        setFormValues({
          orgName: orgData.name || "",
          register: orgData.register || "",
          phone: orgData.phone || "",
          email: orgData.email || "",
          isGiveAward: orgData.isGiveAward || false,
        });
        setSelected(orgData.isGiveAward);
      })
      .catch((error) => {
        console.error("Error fetching organization", error);
      });
  }, [id]);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelected(value === "true");
    setFormValues({
      ...formValues,
      isGiveAward: value === "true",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  function editOrg() {
    return updateOrg({ ...formValues, id })
      .then((response) => {
        if (response.statusCode === 201) {
          console.log("successfully created ");
          message.success("successfully edited org");
        } else {
          message.error("Бүртгэхэд алдаа гарлаа");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function onSubmit(e) {
    e.preventDefault();
    // console.log("Submitted values:", formValues);
    editOrg();
  }

  return (
    <Layout>
      <Header title="Байгууллагын мэдээлэл засах" />
      <div className="p-6">
        {organization && (
          <form onSubmit={onSubmit}>
            <Fieldset className="gap-6 rounded-xl bg-white/5">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <span className="text-[#2C73EB] font-medium text-base">
                    БАЙГУУЛЛАГЫН МЭДЭЭЛЭЛ:
                  </span>
                  <div className="border-b-2 border-[#2C73EB]"></div>
                </div>
                <div className="w-full grid grid-cols-4 gap-6">
                  <Field>
                    <Label className="text-sm/6 font-medium text-slate-700">
                      Байгууллагын нэр <span className="text-red-500">*</span>
                    </Label>
                    <Input
                      value={formValues.orgName}
                      name="orgName"
                      placeholder="Утга бичнэ үү"
                      onChange={handleChange}
                      required={true}
                      className={cn(
                        "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                    />
                  </Field>

                  <Field>
                    <Label className="text-sm/6 font-medium text-slate-700">
                      Байгууллагын Регистер
                      <span className="text-red-500">*</span>
                    </Label>
                    <Input
                      onChange={handleChange}
                      value={formValues.register}
                      placeholder="Утга бичнэ үү"
                      name="register"
                      required={true}
                      className={cn(
                        "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                    />
                  </Field>

                  <Field>
                    <Label className="text-sm/6 font-medium text-slate-700">
                      Холбоо барих утас <span className="text-red-500">*</span>
                    </Label>
                    <Input
                      value={formValues.phone}
                      onChange={handleChange}
                      name="phone"
                      placeholder="Утга бичнэ үү"
                      required={true}
                      className={cn(
                        "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                    />
                  </Field>

                  <Field>
                    <Label className="text-sm/6 font-medium text-slate-700">
                      Цахим шуудангийн хаяг{" "}
                      <span className="text-red-500">*</span>
                    </Label>
                    <Input
                      value={formValues.email}
                      onChange={handleChange}
                      placeholder="Утга бичнэ үү"
                      name="email"
                      type="email"
                      required={true}
                      className={cn(
                        "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                    />
                  </Field>

                  <Field>
                    <Label className="text-sm/6 font-medium text-slate-700">
                      Шагнал өгдөг эсэх <span className="text-red-500">*</span>
                    </Label>
                    <div className="relative">
                      <Select
                        name="isGiveAward"
                        value={formValues.isGiveAward.toString()} // Convert boolean to string for select
                        onChange={handleSelectChange}
                        required={true}
                        className={cn(
                          "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6",
                          " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      >
                        {choices.map((choice) => (
                          <option value={choice.value} key={choice.name}>
                            {choice.name}
                          </option>
                        ))}
                      </Select>
                      <FaChevronDown
                        color="#475569"
                        className="group pointer-events-none absolute top-4 right-3 size-3"
                      />
                    </div>
                  </Field>
                </div>
              </div>

              <Button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Хадгалах
              </Button>
            </Fieldset>
          </form>
        )}
      </div>
    </Layout>
  );
}
