import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { ConfigProvider, message, Space, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { deleteSingleArchive, getArchives } from "../services";
import moment from "moment";
import plus from "../assets/plus-sign.svg";
import CustomSearch from "../components/CustomSearch";
import { MdOutlineEdit, MdDelete } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

export default function Archive() {
  const [archives, setArchives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAward, setSelectedAward] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };
  const deleteArchive = async (id) => {
    try {
      const response = await deleteSingleArchive(id);
      if (
        response.statusCode === 200 ||
        response.statusCode === 204 ||
        response.statusCode === 201 ||
        response.ok
      ) {
        console.log("Successfully deleted");
        message.success("Амжилттай устгагдлаа");
        // setArchives((prevArchives) =>
        //   prevArchives.filter((archive) => archive.id !== id)
        // );
      } else {
        message.error("Устгахад алдаа гарлаа");
      }
    } catch (error) {
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  };
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Өөрийн нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "register",
      key: "register",
    },
    {
      title: "Тушаалын дугаар",
      key: "tushaalName",
      dataIndex: "tushaalName",
    },
    {
      title: "Тушаалын огноо",
      key: "tushaalDate",
      dataIndex: "tushaalDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Шагнал",
      key: "awardTable",
      dataIndex: "awardTable",
      render: (_, record) => record.awardTable?.name,
    },
    {
      title: "Бүртгэсэн огноо",
      key: "date",
      dataIndex: "date",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Төлөв",
      key: "awardState",
      dataIndex: "awardState",
      render: (_, record) => (
        <div className=" py-1 px-3 w-[100px] bg-green-500 m-0 text-center text-white rounded-xl font-semibold text-[12px]">
          {record.awardState?.stateName}
        </div>
      ),
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              <button onClick={() => navigate(`/archive/edit/${record.id}`)}>
                <MdOutlineEdit size={25} />
              </button>
              <button onClick={() => deleteArchive(record.id)}>
                <MdDelete size={25} fill="" color="red" />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getArchives()
      .then((response) => {
        if (response.status === 200) {
          setArchives(response.data);
          setLoading(false);
          console.log("response", response.data.data);
          console.log("archives", archives);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error.message", error.message);
      });
  }, []);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleSearch = (value) => {
    setSearchText(value);
  };
  const filteredDataSource = archives?.data?.filter((item) => {
    return (
      item.register?.toLowerCase().includes(searchText.toLowerCase()) &&
      (!selectedOrg || item.organization === selectedOrg) &&
      (!selectedStatus || item.status === selectedStatus) &&
      (!selectedDate ||
        moment(item.date).format("YYYY-MM-DD") === selectedDate) &&
      (!selectedAward || item.award?.name === selectedAward)
    );
  });
  const sort = ["ascend", "descend", "ascend"];

  return (
    <Layout>
      <Header title="Архив" count={archives.total} />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-between items-center">
          <CustomSearch onSearch={handleSearch} />
          <Link
            to="/archive/create"
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
          >
            <img src={plus} alt="" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </Link>
        </span>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={filteredDataSource}
            scroll={{ x: "100%" }}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomLeft"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
          />
        </ConfigProvider>
      </div>
    </Layout>
  );
}
