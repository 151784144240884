import React, { useState } from "react";
import { Steps, ConfigProvider } from "antd";
import { RegisterArchiveForm } from "./RegisterArchiveForm";
import SuccessfulStep from "./SuccessfulStep";

// interface StepType {
//   title: string;
//   content: React.ReactNode;
// }

// interface StepProps {
//   steps: StepType[];
// }: React.FC<StepProps>

const Step = () => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const steps = [
    { title: "Мэдээлэл", content: <RegisterArchiveForm next={next} /> },
    { title: "Дуусгах", content: <SuccessfulStep /> },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              iconSize: 40,
            },
          },
        }}
      >
        <Steps
          style={{
            width: "320px",
          }}
          current={current}
          labelPlacement="vertical"
          items={items}
          className="font-semibold"
        />
      </ConfigProvider>

      <div className="w-full mt-5">{steps[current].content}</div>
    </>
  );
};

export default Step;
