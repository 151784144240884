import React, { useState, createContext, useContext, useEffect } from "react";
import bg from "../assets/image.png";
import logo from "../assets/logo.svg";
import { Field, Fieldset, Input, Label, Button } from "@headlessui/react";
import { cn } from "../lib/utils";
import { Navigate, useNavigate } from "react-router-dom";

import { message } from "antd";
import AuthContext from "../context/AuthContext";

export default function Auth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login, user } = useContext(AuthContext);

  const navigate = useNavigate();
  console.log(user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      username: username,
      password: password,
    };
    await login(payload);
    navigate("/archive");
    message.success("Амжилттай нэвтэрлээ");
  };

  return (
    <>
      {user ? (
        <Navigate to="/archive" />
      ) : (
        <div className="flex w-screen h-screen">
          <div className="overflow-hidden w-3/4 bg-filter">
            <img
              src={bg}
              alt="bg"
              className=" object-cover bg-cover w-full h-auto"
            />
          </div>
          <div className="w-1/4 flex flex-col items-center justify-center gap-8">
            <img src={logo} alt="logo" />
            <form
              className="w-10/12"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <Fieldset className="flex flex-col gap-4 p-2" disabled={loading}>
                <Field>
                  <Label className="text-sm/5 font-medium ">Имэйл хаяг</Label>
                  <Input
                    type="email"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    className={cn(
                      "mt-3 block w-full rounded-lg text-slate-400 border py-2 px-3 text-sm/6 ",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    placeholder="Имэйл хаягаа энд бичнэ үү"
                  />
                </Field>
                <Field>
                  <Label className="text-sm/5 font-medium">Нууц үг</Label>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className={cn(
                      "mt-3 block w-full rounded-lg text-slate-400 border py-2 px-3 text-sm/6 ",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    placeholder="Нууц үгээ бичнэ үү"
                  />
                  {/* <Link
                    to={"/"}
                    className="text-slate-700 font-normal text-sm/5  flex justify-end mt-4"
                  >
                    Нууц үг сэргээх
                  </Link> */}
                </Field>
                <Button
                  type="submit"
                  className=" bg-[#2C73EB] py-2 px-4 text-white rounded-lg"
                >
                  Нэвтрэх
                </Button>
              </Fieldset>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
