import React, { useContext } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { Button, Field, Fieldset, Input, Label } from "@headlessui/react";
import { cn } from "../lib/utils";
import { useEffect, useState } from "react";
import { createAward } from "../services";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import AuthContext from "../context/AuthContext";

export default function CreateAward() {
  const { user } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({
    name: "",
    orgId: user.orgId,
  });
  const navigate = useNavigate();
  function addAward() {
    return createAward(formValues)
      .then((response) => {
        if (response.statusCode === 201) {
          message.success("Шагнал амжилттай нэмэгдлээ");
          navigate("/awards");
        } else {
          console.error(response.message);
          message.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    await addAward();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    console.log("user", user);
  };
  return (
    <Layout>
      <Header title="Шагнал шинээр бүртгэх" />
      <div className="p-6">
        <form onSubmit={(e) => handleSubmit(e)}>
          <Fieldset className="gap-6 rounded-xl bg-white/5 ">
            <div className="w-full grid grid-cols-2 gap-10">
              <Field>
                <Label>Шагналын нэр</Label>
                <Input
                  //   value={formValues.name}
                  name="name"
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              {/* <Field>
                <Label>Шагналын нэр</Label>
                <Input
                  //   value={formValues.orgName}
                  name="orgName"
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field> */}
            </div>
            <Button
              type="submit"
              className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Хадгалах
            </Button>
          </Fieldset>
        </form>
      </div>
    </Layout>
  );
}
