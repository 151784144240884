import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAwardOrgs,
  getAwards,
  getSingleArchive,
  updateArchive,
} from "../services";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Select,
  Textarea,
  Button,
} from "@headlessui/react";
import { cn } from "../lib/utils";
import { FaChevronDown } from "react-icons/fa";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { message } from "antd";

const choices = [
  { name: "Тийм", value: true },
  { name: "Үгүй", value: false },
];

export default function EditArchive() {
  const { id } = useParams();

  const [archive, setArchive] = useState(null);
  const [awards, setAwards] = useState();
  const [awardOrgs, setAwardOrgs] = useState();
  const [loading, setLoading] = useState(true);
  const [awardId, setAwardId] = useState(0);
  const [awardOrgId, setAwardOrgId] = useState(1);
  const [formValues, setFormValues] = useState({
    org: awardOrgId,
    award: awardId,
    register: "",
    firstName: "",
    lastName: "",
    licenseNum: "",
    orgName: "",
    orderDate: "",
    orderNum: "",
    phoneNumber: "",
    email: "",
    position: "",
    description: "",
  });
  useEffect(() => {
    getSingleArchive({ id })
      .then((response) => {
        console.log("archive response", response);
        const archiveData = response.data;
        setArchive(archiveData);
        console.log("archiveData+++++++++++", archiveData);
        setFormValues({
          org: archiveData.awardOrgId,
          award: archiveData.awardId,
          register: archiveData.register,
          firstName: archiveData.firstName,
          lastName: archiveData.lastName,
          licenseNum: archiveData.passportNumber,
          orgName: archiveData.orgName,
          orderDate: archiveData.tushaalDate,
          orderNum: archiveData.tushaalName,
          phoneNumber: archiveData.phone,
          email: archiveData.email,
          position: archiveData.position,
          description: archiveData.description,
        });
      })
      .catch((error) => {
        console.error("Error fetching organization", error);
      });
  }, [id]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    if (name === "award") {
      setAwardId(value);
    } else if (name === "org") {
      setAwardOrgId(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  function fetchAwards() {
    getAwards()
      .then((response) => {
        setAwards(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchOrgs() {
    getAwardOrgs()
      .then((response) => {
        if (response.status === 200) {
          setAwardOrgs(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchOrgs();
    fetchAwards();
  }, []);
  async function editSingleArchive() {
    try {
      const response = await updateArchive({ ...formValues, id });

      if (response.statusCode === 201 || response.ok) {
        console.log("successfully created ");
        message.success("Засвар амжилттай хийгдлээ");
      } else {
        message.error("Бүртгэхэд алдаа гарлаа");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    editSingleArchive();
  }

  return (
    <Layout>
      <Header title="Олгосон шагналын мэдээлэл засах" />
      <div className="p-6">
        {archive && (
          <>
            <form onSubmit={(e) => onSubmit(e)}>
              <Fieldset className="gap-6 rounded-xl bg-white/5 ">
                <div className="flex flex-col gap-4 pb-5 ">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <span className=" text-[#2C73EB] font-medium text-base">
                        ШАГНАЛ СОНГОХ:
                      </span>
                      <div className="border-b-2 border-[#2C73EB]"></div>
                    </div>

                    <div className="w-full grid grid-cols-4 gap-6">
                      <Field>
                        <Label className="text-sm/6 font-medium text-slate-700">
                          Харьяалагдах яам
                        </Label>
                        <div className="relative">
                          <Select
                            name="org"
                            value={awardOrgId}
                            onChange={handleSelectChange}
                            defaultValue={1}
                            className={cn(
                              "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                              " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                            )}
                          >
                            {awardOrgs &&
                              awardOrgs.map((awardOrg) => (
                                <option value={awardOrg.id} key={awardOrg.id}>
                                  {awardOrg.name}
                                </option>
                              ))}
                          </Select>
                          <FaChevronDown
                            color="#475569"
                            className=" group pointer-events-none absolute top-4 right-3 size-3 "
                          />
                        </div>
                      </Field>
                      <Field>
                        <Label className="text-sm/6 font-medium text-slate-700">
                          Шагналын нэр
                        </Label>
                        <div className="relative">
                          <select
                            className={cn(
                              "mt-3 text-[#475569] block w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                              " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                            )}
                            value={awardId}
                            name="award"
                            onChange={handleSelectChange}
                            defaultValue={1}
                          >
                            {awards &&
                              awards.map((award) => (
                                <option value={award.id} key={award.id}>
                                  {award.name}
                                </option>
                              ))}
                          </select>
                          <FaChevronDown
                            color="#475569"
                            className=" group pointer-events-none absolute top-4 right-3 size-3 "
                          />
                        </div>
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <span className=" text-[#2C73EB] font-medium text-base ">
                      ХУВИЙН МЭДЭЭЛЭЛ:
                    </span>
                    <div className="border-b-2 border-[#2C73EB]"></div>
                  </div>
                  <div className="w-full  grid grid-cols-4 gap-6">
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Регистер
                      </Label>

                      <Input
                        onChange={handleChange}
                        value={formValues.register}
                        placeholder="Утга бичнэ үү"
                        name="register"
                        required={true}
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Эцэг эхийн нэр
                      </Label>

                      <Input
                        value={formValues.lastName}
                        name="lastName"
                        placeholder="Утга бичнэ үү"
                        onChange={handleChange}
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Өөрийн нэр
                      </Label>

                      <Input
                        value={formValues.firstName}
                        name="firstName"
                        placeholder="Утга бичнэ үү"
                        onChange={handleChange}
                        required={true}
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Албан тушаал
                      </Label>
                      <Input
                        value={formValues.position}
                        placeholder="Утга бичнэ үү"
                        onChange={handleChange}
                        name="position"
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Үнэмлэхний дугаар
                      </Label>

                      <Input
                        placeholder="Утга бичнэ үү"
                        value={formValues.licenseNum}
                        onChange={handleChange}
                        name="licenseNum"
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Ажилладаг байгууллагын нэр
                      </Label>

                      <Input
                        placeholder="Утга бичнэ үү"
                        value={formValues.orgName}
                        onChange={handleChange}
                        name="orgName"
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>

                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Тушаалын огноо
                      </Label>

                      <Input
                        value={formValues.orderDate}
                        onChange={handleChange}
                        placeholder="Огноо бичнэ үү YYYY/MM/DD"
                        name="orderDate"
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Тушаалын дугаар
                      </Label>

                      <Input
                        value={formValues.orderNum}
                        onChange={handleChange}
                        name="orderNum"
                        placeholder="Утга бичнэ үү"
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>

                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Холбоо барих утас
                      </Label>

                      <Input
                        value={formValues.phoneNumber}
                        onChange={handleChange}
                        name="phoneNumber"
                        placeholder="Утга бичнэ үү"
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                    <Field>
                      <Label className="text-sm/6 font-medium text-slate-700">
                        Цахим шуудангийн хаяг
                      </Label>

                      <Input
                        value={formValues.email}
                        onChange={handleChange}
                        placeholder="Утга бичнэ үү"
                        name="email"
                        className={cn(
                          "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                      />
                    </Field>
                  </div>
                  <Field className="w-3/12">
                    <Label className="text-sm/6 font-medium text-slate-700">
                      Нэмэлт тайлбар
                    </Label>

                    <Textarea
                      value={formValues.description}
                      placeholder="Утга бичнэ үү"
                      onChange={handleChange}
                      name="description"
                      className={cn(
                        "mt-3 block w-full resize-none rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      rows={3}
                    />
                  </Field>
                </div>

                <Button
                  type="submit"
                  className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                >
                  Хадгалах
                </Button>
              </Fieldset>
            </form>
          </>
        )}
      </div>
    </Layout>
  );
}
