import { useState, useContext } from "react";
import hammer from "../assets/sidebar/Vector.svg";
import clipboard from "../assets/sidebar/clipboard.svg";
import clipboardActive from "../assets/sidebar/clipboardwhite.svg";
import logout1 from "../assets/sidebar/logout-01.svg";
import logo from "../assets/sidebar/logo.svg";
import logosmall from "../assets/sidebar/logosmall.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import usergroup from "../assets/sidebar/user-group.svg";
import usergroupactive from "../assets/sidebar/user-groupactive.svg";
import awards from "../assets/sidebar/award-02.svg";
import awardactive from "../assets/sidebar/awardwhite.svg";
import { message } from "antd";
import AuthContext from "../context/AuthContext";
import { FaRegUser } from "react-icons/fa";
import SidebarContext from "../context/SidebarContext";

export default function Sidebar({}) {
  const { isCollapsed, toggleSidebar } = useContext(SidebarContext);
  const { logout, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const routes = [
    {
      name: "Архив",
      icon: clipboard,
      path: "/archive",
      subPath: "/archive/create",
      active: clipboardActive,
    },
    {
      name: "Байгууллагын жагсаалт",
      icon: usergroup,
      path: "/organizations",
      subPath: "/organizations/create",
      active: usergroupactive,
    },
    {
      name: "Салбар байгууллагуудын жагсаалт",
      icon: usergroup,
      subPath: "",
      path: "/suborgs",
      active: usergroupactive,
    },
    {
      name: "Шагналууд",
      icon: awards,
      subPath: "",
      path: "/awards",
      active: awardactive,
    },
    {
      name: "Шагнал олгох журам",
      icon: hammer,
      subPath: "/awards/edit/:id",
      path: "/",
    },
  ];

  // const toggleSidebar = () => {
  //   setIsCollapsed(!isCollapsed);
  // };
  const location = useLocation();
  const pathname = location.pathname;
  const onLogout = async () => {
    await logout();
    navigate("/auth");
    message.info("Амжилттай гарлаа");
  };

  return (
    <div
      className={`${
        isCollapsed ? "w-[96px]" : "w-[330px]"
      } transition-width duration-300 bg-gray-50 relative flex flex-col justify-between h-screen px-5 pt-8 pb-10 border-r border-r-slate-200`}
    >
      <div
        onClick={toggleSidebar}
        className="absolute w-2 top-0 bottom-0 right-0 z-[100] positioning cursor-pointer group"
      >
        <div className=" absolute line  opacity-0 transform -translate-x-1/2 transition-opacity duration-300 ease-[cubic-bezier(0.25,0.01,0.25,1)] group-hover:opacity-100 left-1/2 top-0 bottom-0 line w-[2px] h-full bg-blue-700"></div>
        <div className=" absolute z-[150] top-12 rounded-[50%] left-1/2 line opacity-0 translate-50 transform -translate-x-1/2 transition-opacity-transform duration-200 ease-custom-ease group-hover:opacity-100 flex items-center justify-center w-6 h-6 bg-blue-700">
          {isCollapsed ? (
            <FaAngleRight color="white" height={20} width={20} />
          ) : (
            <FaAngleLeft color="white" height={20} width={20} />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-12">
        <div className="flex justify-between items-center">
          {isCollapsed ? (
            <img
              src={logosmall}
              alt="logo"
              width={56}
              height={56}
              className=" object-cover bg-cover"
            />
          ) : (
            <img src={logo} alt="logo" width={254} height={56} />
          )}
        </div>
        <div className="flex flex-col gap-5">
          {routes.slice(0, 4).map((route, index) => (
            <Link
              to={route.path}
              className={`flex rounded-[8px] p-3 gap-2 transition-all ease-in duration-300 ${
                pathname === route.path || pathname === route.subPath
                  ? "bg-[#2c73eb] text-white"
                  : "bg-slate-100 text-slate-700"
              } ${isCollapsed ? "justify-center" : ""}`}
              key={index}
            >
              {pathname === route.path || pathname === route.subPath ? (
                <img src={route.active} alt="icon" width={20} height={20} />
              ) : (
                <img src={route.icon} alt="icon" width={20} height={20} />
              )}
              {!isCollapsed && (
                <span className=" text-sm font-medium">{route.name}</span>
              )}
            </Link>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-end gap-2 mb-12">
        {/* {routes.slice(2, 3).map((route, index) => (
          <Link
            to={route.path}
            className={`flex rounded-[8px] p-3 gap-2 ${
              isCollapsed ? "justify-center" : ""
            }`}
            key={index}
          >
            <img src={route.icon} alt="icon" width={20} height={20} />
            {!isCollapsed && (
              <span className="text-sm font-medium">{route.name}</span>
            )}
          </Link>
        ))} */}{" "}
        <div
          className={`flex ${
            isCollapsed ? "justify-center" : "justify-between gap-2"
          } rounded-[8px] p-3  bg-slate-100`}
        >
          <div className="flex items-center gap-2">
            <span className="rounded-full bg-gray-300 p-2 flex justify-center">
              <FaRegUser size={15} />
            </span>
            {!isCollapsed && <span>{user.firstName || ""}</span>}
          </div>

          <button onClick={onLogout}>
            <img src={logout1} alt="icon" width={20} height={20} />
          </button>
        </div>
      </div>
    </div>
  );
}
