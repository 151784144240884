import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Auth from "./pages/Auth";
import Archive from "./pages/Archive";
import Create from "./pages/Create";
import VerifyUser from "./pages/UserVerify";
import Register from "./pages/Register";

import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import CreateOrg from "./pages/CreateOrg";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";
import EditOrg from "./pages/EditOrg";
import EditArchive from "./pages/EditArchive";
import { SidebarProvider } from "./context/SidebarContext";
import Awards from "./pages/Awards";
import EditAward from "./pages/EditAwards";
import CreateAward from "./pages/CreateAward";
import SubOrgs from "./pages/SubOrgs";

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Navigate to="/auth" />;
  }

  return children;
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/archive" />,
  },
  {
    path: "/archive",
    element: (
      <ProtectedRoute>
        <Archive />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/archive/create",
    element: (
      <ProtectedRoute>
        <Create />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/archive/edit/:id",
    element: (
      <ProtectedRoute>
        <EditArchive />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
  // {
  //   path: "/user/verify",
  //   element: <VerifyUser />,
  // },
  {
    path: "/organizations",
    element: (
      <ProtectedRoute>
        <Register />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/organizations/create",
    element: (
      <ProtectedRoute>
        <CreateOrg />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  // {
  //   path: "/organizations/edit/:id",
  //   element: (
  //     <ProtectedRoute>
  //       <EditOrg />
  //     </ProtectedRoute>
  //   ),
  //   errorElement: <div>404 not found</div>,
  // },
  {
    path: "/archive/edit/:id",
    element: (
      <ProtectedRoute>
        <EditOrg />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/awards",
    element: (
      <ProtectedRoute>
        <Awards />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/awards/edit/:id",
    element: (
      <ProtectedRoute>
        <EditAward />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/awards/create",
    element: (
      <ProtectedRoute>
        <CreateAward />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/suborgs",
    element: (
      <ProtectedRoute>
        <SubOrgs />
      </ProtectedRoute>
    ),
    errorElement: <div>404 not found</div>,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <SidebarProvider>
        <RouterProvider router={router} />
      </SidebarProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
