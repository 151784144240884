import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { getOrganizations } from "../services";
import plus from "../assets/plus-sign.svg";
import moment from "moment";
import { MdOutlineEdit, MdDelete } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const [orgs, setOrgs] = useState([]);
  const [showMore, setShowMore] = useState();
  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Байгууллагын нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "register",
      key: "register",
    },
    {
      title: "Холбогдох утасны дугаар",
      key: "phone",
      dataIndex: "phone",
      // render: (text) => moment(text).format("YYYY-MM-DD "),
    },
    {
      title: "Цахим шуудан хаяг",
      key: "email",
      dataIndex: "email",
      // render: (text) => moment(text).format("YYYY-MM-DD "),
    },
    {
      title: "Бүртгэсэн огноо",
      key: "date",
      dataIndex: "date",
      render: (text) => moment(text).format("YYYY-MM-DD "),
    },
    // {
    //   title: "Үйлдэл",
    //   key: "action",
    //   dataIndex: "action",
    //   render: (_, record) => (
    //     <div style={{ position: "relative", display: "inline-block" }}>
    //       {!showMore && (
    //         <button onClick={toggleMenu} className="font-bold">
    //           <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
    //         </button>
    //       )}

    //       {showMore && (
    //         <div className="flex gap-2 items-center justify-between">
    //           <button
    //             onClick={() => navigate(`/organizations/edit/${record.id}`)}
    //           >
    //             <MdOutlineEdit size={25} />
    //           </button>
    //           <button onClick={() => alert("Delete clicked")}>
    //             <MdDelete size={25} fill="" color="red" />
    //           </button>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  function fetchOrgs() {
    getOrganizations()
      .then((response) => {
        setOrgs(response.data);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    fetchOrgs();
  }, []);
  return (
    <Layout>
      <Header title="Байгууллагын жагсаалт" />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-end">
          <Link
            to="/organizations/create"
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
          >
            <img src={plus} alt="add" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </Link>
        </span>
        <Table
          columns={columns}
          dataSource={orgs}
          pagination={{
            position: ["bottomLeft"],
          }}
        />
      </div>
    </Layout>
  );
}
