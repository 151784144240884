import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { deleteAward, getAwards } from "../services";
import { ConfigProvider, Table, message } from "antd";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit, MdDelete } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import plus from "../assets/plus-sign.svg";
import { Link } from "react-router-dom";

export default function Awards() {
  const [awards, setAwards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showMore, setShowMore] = useState();

  async function fetchAwards() {
    const response = await getAwards();
    console.log("response", response);
    setAwards(response.data);
  }
  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };
  useEffect(() => {
    fetchAwards();
  }, []);
  const removeAward = async (id) => {
    try {
      const response = await deleteAward(id);
      if (response.statusCode === 201 || response.statusCode === 200) {
        setAwards((prevData) => prevData.filter((item) => item.id !== id));
        console.log("Successfully deleted");
        message.success("Амжилттай устгагдлаа");
      } else {
        message.error("Устгахад алдаа гарлаа");
      }
    } catch (error) {
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Байгууллагын нэр",
      key: "organization",
      dataIndex: "organization",
      render: (_, record) => record.organization?.name,
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              <button onClick={() => navigate(`/awards/edit/${record.id}`)}>
                <MdOutlineEdit size={25} />
              </button>
              <button onClick={() => removeAward(record.id)}>
                <MdDelete size={25} fill="" color="red" />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  return (
    <Layout>
      <Header title="Шагналууд" />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-end items-center">
          <Link
            to="/awards/create"
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
          >
            <img src={plus} alt="" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </Link>
        </span>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={awards}
            scroll={{ x: "100%" }}
            rowKey="id"
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomLeft"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
          />
        </ConfigProvider>
      </div>
    </Layout>
  );
}
