import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleAward, updateAward } from "../services";
import { Field, Fieldset, Input, Label, Button } from "@headlessui/react";
import { cn } from "../lib/utils";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { message } from "antd";

export default function EditAward() {
  const { id } = useParams();
  const [awards, setAwards] = useState();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    name: "",
    orgName: id,
  });
  useEffect(() => {
    getSingleAward({ id })
      .then((response) => {
        console.log("archive response", response);
        const awardData = response.data;
        setAwards(awardData);
        console.log("awardData+++++++++++", awardData);
        setFormValues({
          name: awardData.name,
        });
      })
      .catch((error) => {
        console.error("Error fetching organization", error);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  async function editSingleAward() {
    try {
      const response = await updateAward({ ...formValues, id });

      if (response.result) {
        console.log("successfully created ");
        message.success("Засвар амжилттай хийгдлээ");
        navigate("/awards");
      } else {
        message.error("Засвар хийхэд алдаа гарлаа");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    editSingleAward();
  }

  return (
    <Layout>
      <Header title="Шагнал шинээр бүртгэх" />
      <div className="p-6">
        <form onSubmit={(e) => onSubmit(e)}>
          <Fieldset className="gap-6 rounded-xl bg-white/5 ">
            <div className="w-full grid grid-cols-2 gap-10">
              <Field>
                <Label>Шагналын нэр</Label>
                <Input
                  value={formValues.name}
                  name="name"
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
            </div>
            <Button
              type="submit"
              className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Хадгалах
            </Button>
          </Fieldset>
        </form>
      </div>
    </Layout>
  );
}
