import React, { useState } from "react";
import Sidebar from "./Sidebar";

export const Layout = ({ children }) => {
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="w-full flex-grow h-screen overflow-y-auto">
        {children}
      </div>
    </div>
  );
};
