import { Input, ConfigProvider } from "antd";

const { Search } = Input;

const CustomSearch = ({ onSearch }) => {
  const handleSearch = (value) => {
    onSearch(value);
  };
  const handleChange = () => {};
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorTextBase: `#616D7E`,
            borderRadius: "12px",
            colorBorder: "rgba(160, 174, 192, 0.7)",
          },
        }}
      >
        <Search
          placeholder="Хайх"
          onChange={handleChange}
          onSearch={handleSearch}
          style={{ maxWidth: 280, borderRadius: 12 }}
        />
      </ConfigProvider>
    </>
  );
};

export default CustomSearch;
